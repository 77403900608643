<template>
  <el-config-provider :locale="locale">
    <div>
      <router-view @loadCas="loadCas" @logoutCas="logoutCas" />
      <iframe
        v-if="isTopWindow"
        :src="iframeUrl"
        frameborder="0"
        style="display: none"
        sandbox="allow-forms allow-scripts allow-same-origin"
      ></iframe>
    </div>
  </el-config-provider>
</template>

<script>
import zhCn from 'element-plus/es/locale/lang/zh-cn' // 简体中文
import en from 'element-plus/es/locale/lang/en' // English
import pt from 'element-plus/es/locale/lang/pt' // 葡萄牙语
import es from 'element-plus/es/locale/lang/es' // 西班牙语
import fr from 'element-plus/es/locale/lang/fr' // 法语
import th from 'element-plus/es/locale/lang/th' // 泰语
import ar from 'element-plus/es/locale/lang/ar' // 阿拉伯语
import de from 'element-plus/es/locale/lang/de'
export default {
  data() {
    return {
      iframeUrl: ''
    }
  },
  methods: {
    loadCas(url) {
      this.iframeUrl = url
    },
    logoutCas(url) {
      this.iframeUrl = url
    }
  },
  computed: {
    locale: function () {
      let lang = zhCn
      switch (this.$i18n.locale) {
        case 'en_US':
          lang = en
          break
        case 'es_ES':
          lang = es
          break
        case 'th_TH':
          lang = th
          break
        case 'pt_BR':
          lang = pt
          break
        case 'fr_FR':
          lang = fr
          break
        case 'ar_SA':
          lang = ar
          break
        case 'de_DE':
          lang = de
          break
      }
      return lang
    },
    isTopWindow: function () {
      return top.window == window
    }
  }
}
</script>

<style lang="less">
body.RTL {
  .chat-card-view,
  .rich-Text-dialog_preview,
  .image-library-page {
    img {
      transform: none !important;
    }
  }
  .aicc-input-dialog .el-input__wrapper:not(.aicc-input-dialog .el-input__wrapper:has(.el-icon.el-input__icon.el-input__clear))::after {
    left: 12px !important;
    right: unset;
  }
}
.is-message-box:has(.time-out-message-box) {
    background-color: white !important;
    z-index: 100000 !important;
}

.aicc-input-dialog .el-input__wrapper:not(.aicc-input-dialog .el-input__wrapper:has(.el-icon.el-input__icon.el-input__clear))::after {
  position: absolute;
  content: "";
  cursor: pointer;
  background: url('../../../src/assets/img/input_dialog.png') no-repeat center;
  background-size: 100% 100%;
  height: 16px;
  width: 16px;
  z-index: 0;
  right: 12px;
  pointer-events: none;
}
</style>
