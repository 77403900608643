import {createApp} from 'vue'
import {createPinia} from 'pinia'
import App from './App.vue'
import {moduleRoutes, router} from '@/router'
import cookieUtils from '@/utils/cookie'
import timeUtils from '@/utils/timeUtils'
import validate from '@/utils/validate'
import {setDirection} from '@/utils/rtl.js'
import {setApibuilderClass} from '@/utils/apibuilder.js'
import registComponents from '@/components'
import {useBaseStore, useEventStore} from '@/views/base/stores'

import ElementPlus from 'element-plus'
import sweetBase from '@hw-seq/sweet-ui-base'
import '@hw-seq/sweet-ui-base/dist/sweet-ui-base.css'

import '@/plugin/tinymce/index.js'
import '@/plugin/filterxss/index.js'
import $ from 'jquery'

import {NextGraph} from '@/next-graph/next-graph';
import '@/next-graph/style.css'
import i18n from '@/lang'
import {initSessionListener} from '@/utils/session.js'
import AiccElMessage from '@/utils/el-message'

initSessionListener()

window.$ = $;
window.jQuery = $;

if (window != window.top) {
  if (top.window.isNewSystem == false) {
    // 新老页面兼容 异常登录场景
    window.top.location.replace(window.location.href)
  } else {
    throw new Error("Vue app should not be embedded in an iframe.");
  }
}

const app = createApp(App)
registComponents(app)
// 需要和sweetUI兼容的国际化
const i18nLocaleMap = {
  'pt_BR': 'pt_PT'
}
sweetBase.i18n(i18nLocaleMap[i18n.global.locale] || i18n.global.locale, app)

top.window.isNewSystem = true;

app.use(createPinia())

const baseStore = useBaseStore()
const eventStore = useEventStore()

baseStore.moduleRoutes = moduleRoutes

// 记录历史路由栈
const originPush = router.push
router.push = function push(route) {
  const routesSet = new Set(baseStore.keepAliveRouteNames)
  routesSet.add(route.name)
  baseStore.keepAliveRouteNames = [...routesSet]
  if (route.title) {
    baseStore.routeTitles[route.name] = route.title
  }
  try {
    return originPush.call(this, route)
  } catch (error) {}
}

app.use(router)

app.use(i18n)



// 提供rtl能力
window.setDirection = setDirection
window.isRTL = setDirection(document)

// 适配apibuilder样式
setApibuilderClass()

// 大屏监控需要新开窗口，禁止跳转到主页
if (window.location.href.indexOf('fullscreen-monitor') === -1) {
  router.replace('/');
}

// 将国际化$t注入全局变量中
app.config.globalProperties.$t = i18n.global.t

// 将cookieUtils挂载到全局
app.config.globalProperties.cookieUtils = cookieUtils

// 将全局事件store挂载到全局
app.config.globalProperties.$event = eventStore

// 将全局变量isIndependentDeployment挂载到全局
app.config.globalProperties.isIndependentDeployment = baseStore.isIndependentDeployment

// 将全局变量isApiBuilderDeployment挂载到全局
app.config.globalProperties.isApiBuilderDeployment = baseStore.isApiBuilderDeployment

// 环境变量
app.config.globalProperties.env = import.meta.env

// 将timeUtils挂载到全局
app.config.globalProperties.$TimeUtils = timeUtils

// 将validate校验方法挂载到全局
app.config.globalProperties.$Validate = validate

sweetBase.setTheme('light')
app.use(ElementPlus)
app.use(sweetBase) // 全量安装
// 添加AiccElMessage，按照UCD要求覆盖ElMessage，覆盖原来绑定在全局的$message
app.config.globalProperties.$message = AiccElMessage
app.mount('#app')
window.app = app

window['useEventStore'] = () => useEventStore();

app.use(NextGraph);
